import styled from '@emotion/styled';
import { Button } from 'components/atoms/button';
import Img from 'components/atoms/img';
import { JaHeading } from 'components/atoms/jaHeading';
import { List } from 'components/atoms/list';
import { SnsIcon, SnsIconType } from 'components/atoms/snsicon';
import { Text } from 'components/atoms/text';
import { MediaGrid } from 'components/molecules/mediaGrid';
import { TextSet } from 'components/molecules/textSet';
import { TopicsListWrap } from 'components/molecules/topicsListWrap';
import { TopicsSectionWrap } from 'components/molecules/topicsSectionWrap';
import { ContentInner } from 'components/organisms/contentInner';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import { StudioDetailMain } from 'components/organisms/studioDetailMain';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import * as React from 'react';
import colors from 'style/colors';
import sizes from 'style/sizes';
import { mq } from 'style/variables';
import { ProjectCard } from 'components/molecules/projectCard';
import RangeSlide from 'components/organisms/rangeSlide/';
import { StudiosDetailContext } from '../../gatsby-node/createStudiosDetailPages';
import { StudiosDetailEnQuery } from 'types/graphql-types';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import { NumbersObject, ProjectDetailNumbers } from 'components/organisms/projectDetailNumbers';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
type Props = {
  pageContext: StudiosDetailContext;
  data: StudiosDetailEnQuery;
};

const StudioSnsIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${sizes.margin[81]};
  ${mq.onlysp} {
    margin-top: ${sizes.margin[64]};
    align-items: center;
  }
  > a + a {
    margin-left: 32px;
  }
`;

const StudioButton = styled(Button)`
  margin: 0 auto;
  font-size: 18px;
  height: 47px;
  letter-spacing: ${1.36 / 18}em;
  ${mq.onlypc} {
    min-width: 425px;
  }
  ${mq.onlysp} {
    font-size: 15px;
    width: 278px;
  }
`;

const StudioImgGrid = styled(MediaGrid)`
  ${mq.onlypc} {
    margin-bottom: -16px;
  }
`;

const StudioDetailSlide = styled.div`
  padding-top: 65px;
  background-color: rgba(242, 242, 242, 0.32);
  margin-top: ${sizes.margin[180]};
  margin-bottom: 64px;
  ${mq.onlysp} {
    margin-top: ${sizes.margin[100]};
  }
`;

const StudioDetailContacts = styled.div`
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: ${sizes.margin[180]};
  ${mq.onlysp} {
    padding-bottom: ${sizes.margin[100]};
  }
`;

const Pages: React.FC<Props> = ({ data }) => {
  const acf = data.shifterStudiosData?.acf;
  const title = data.shifterStudiosData?.title;
  const projects = data.allShifterProjectsData.edges;

  return (
    <>
      <SEO title={`${title?.rendered}｜Studios`} en />
      <StudioDetailMain
        type="studio"
        imgpath={[acf?.kv_sp || `noimage/img-card-kv-sp.jpg`, acf?.kv || `noimage/img-card-kv.jpg`]}
        alt={''}
        country={acf?.country || ''}
        address={{
          address: acf?.address || '',
          mapUrl: acf?.map || undefined,
          contactUrl: acf?.contact_en || '',
        }}
      >
        <JaHeading size={'large'}>{title?.rendered || 'No Title'}</JaHeading>
        <Text
          isDiv
          dangerouslySetInnerHTML={{
            __html: acf?.text_en || 'No Text',
          }}
        />
      </StudioDetailMain>
      {projects && projects?.length > 0 && (
        <StudioDetailSlide>
          <RangeSlide perView={3} title={'Projects'} viewMore={'View More'} isplacedbottom>
            {projects?.map((project, idx) => (
              <ProjectCard
                key={`projects-${project.node?.id}-${idx}`}
                size={'small'}
                imgpath={project?.node?.acf?.thumb || `noimage/img-card-thumb.jpg`}
                title={project?.node?.acf?.headline_en || 'NO TITLE'}
                client={project?.node?.acf?.client_abbr?.en || undefined}
                work={project?.node?.acf?.product?.en || undefined}
                tags={project?.node?.acf?.outline as string[]}
                to={`/en/projects/${project?.node?.slug}`}
                isEn
              />
            ))}
          </RangeSlide>
        </StudioDetailSlide>
      )}
      {acf?.modules?.map((module, idx) => {
        if (module?.pic_triple && module?.pic_triple.pic1) {
          return (
            <ContentWrapper key={`module-${idx}`}>
              <MediaGrid
                imgs={[
                  {
                    path: module?.pic_triple.pic1 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 1,
                  },
                  {
                    path: module?.pic_triple.pic2 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                  {
                    path: module?.pic_triple.pic3 || `noimage/img-card-thumb.jpg`,
                    alt: '',
                    column: 2,
                  },
                ]}
              />
            </ContentWrapper>
          );
        } else if (module?.text_set) {
          const headline1 = module?.text_set?.find(content => content?.type === 'headline1_en')?.headline1_en;
          const text1 = module?.text_set?.find(content => content?.type === 'text_en')?.text_en;
          return (
            <ContentWrapper isStudioDetail>
              <TopicsSectionWrap>
                <ContentInner>
                  <TextSet>
                    <JaHeading size={'middle'}>{headline1}</JaHeading>
                    <Text isDiv dangerouslySetInnerHTML={{ __html: text1 as string }} />
                  </TextSet>
                </ContentInner>
              </TopicsSectionWrap>
            </ContentWrapper>
          );
        } else if (module?.pic_large) {
          return (
            <TopicsSectionWrap key={`module-${idx}`}>
              <Img filename={module?.pic_large || 'noimage/img-card-thumb.jpg'} alt={''} />
            </TopicsSectionWrap>
          );
        } else if (module?.pic_quadruple?.pic1) {
          return (
            <ContentWrapper key={`module-${idx}`} noSpPadding>
              <TopicsSectionWrap>
                <StudioImgGrid
                  imgs={[
                    {
                      path: module?.pic_quadruple?.pic1 || 'noimage/img-card-thumb.jpg',
                      alt: '',
                      column: 1,
                    },
                    {
                      path: module?.pic_quadruple?.pic2 || 'noimage/img-card-thumb.jpg',
                      alt: '',
                      column: 2,
                    },
                    {
                      path: module?.pic_quadruple?.pic3 || 'noimage/img-card-thumb.jpg',
                      alt: '',
                      column: 2,
                    },
                    {
                      path: module?.pic_quadruple?.pic4 || 'noimage/img-card-thumb.jpg',
                      alt: '',
                      column: 1,
                    },
                  ]}
                  spGrid
                  noSpPadding
                />
              </TopicsSectionWrap>
            </ContentWrapper>
          );
        } else if (module?.visual_set?.visual) {
          return (
            <ContentWrapper noSpPadding>
              <TopicsSectionWrap>
                <Img filename={module?.visual_set?.visual || 'noimage/img-card-thumb.jpg'} alt={''} />
              </TopicsSectionWrap>
            </ContentWrapper>
          );
        } else if (module?.appeal?.label) {
          const appeal = module?.appeal?.fact
            ?.map(fact => {
              return {
                title: fact?.headline,
                number: ((fact?.number as string) + (fact?.change !== 'nothing' ? fact?.change : '')) as string,
              };
            })
            .filter(fact => fact?.title && fact?.number) as NumbersObject[];
          return <ProjectDetailNumbers title="Data" numbers={appeal} numbersCol={2}></ProjectDetailNumbers>;
        }
      })}

      {/**
       * TODO: KUALA LUMPURのみ、ContactとSNSを表示しないようにしている
       * コンテンツが拡充されたら表示されるようにする。
       */}
      {title?.rendered !== 'KUALA LUMPUR' ? (
        <ContentWrapper>
          <TopicsSectionWrap>
            <StudioDetailContacts>
              <StudioButton href={acf?.contact_en || ''} type={'fill'} blank>
                Contact
              </StudioButton>
              {acf?.sns && (
                <StudioSnsIcons>
                  {acf?.sns?.map((sns, idx) => (
                    <SnsIcon key={sns?.type || idx} to={sns?.url || ''} name={sns?.type as SnsIconType} fill />
                  ))}
                </StudioSnsIcons>
              )}
            </StudioDetailContacts>
          </TopicsSectionWrap>
        </ContentWrapper>
      ) : (
        <> </>
      )}
      <InquiryBlock en />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query StudiosDetailEn($slug: String!, $studio: String!) {
    site {
      siteMetadata {
        title
      }
    }
    shifterStudiosData(slug: { eq: $slug }) {
      id
      title {
        rendered
      }
      acf {
        thumb
        text_en
        text
        map
        kv_sp
        kv
        country
        contact_en
        address
        modules {
          block {
            sections {
              pic {
                url
              }
              text
              headline
            }
            title
          }
          info {
            contact
            text
          }
          pic_large
          pic_triple {
            pic1
            pic2
            pic3
          }
          pic_quadruple {
            pic1
            pic2
            pic3
            pic4
          }
          visual_set {
            caption
            size
            visual
          }
          text_set {
            headline1
            headline1_en
            headline2_en
            headline3_en
            text
            text_en
            type
            ol {
              li {
                ja
                en
              }
            }
            ul {
              li {
                ja
                en
              }
            }
          }
          appeal {
            label
            layout
            fact {
              change
              headline
              number
            }
          }
        }
        sns {
          type
          url
        }
      }
    }
    allShifterProjectsData(
      filter: {
        acf: { studios: { elemMatch: { post_title: { eq: $studio } } } }
        title: { rendered: { nin: ["dummy", null] } }
      }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          acf {
            headline_en
            client_abbr {
              en
              ja
            }
            thumb
            outline
            product {
              ja
              en
            }
          }
          slug
        }
      }
    }
  }
`;
